// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".unlocked-cards_unlocked-cards-wrapper_CAOmI {\n    position: absolute;\n    left: 0;\n    right: 0;\n    z-index: 10;\n    max-height: 1000px;\n    background-color: #ffcd2a;\n    transition: max-height 0.5s;\n    overflow: hidden;\n}\n\n.unlocked-cards_unlocked-cards-wrapper-collapsed_37-6d {\n    max-height: 0;\n}\n\n.unlocked-cards_unlocked-cards-heading_1f_ym {\n    font-size: 1.5rem;\n    font-weight: bold;\n    text-align: center;\n    margin-top: 20px;\n    padding: 0 20px;\n}\n\n.unlocked-cards_unlocked-cards-items-container_3RX6q {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    padding: 20px 0;\n}", ""]);
// Exports
exports.locals = {
	"unlocked-cards-wrapper": "unlocked-cards_unlocked-cards-wrapper_CAOmI",
	"unlockedCardsWrapper": "unlocked-cards_unlocked-cards-wrapper_CAOmI",
	"unlocked-cards-wrapper-collapsed": "unlocked-cards_unlocked-cards-wrapper-collapsed_37-6d",
	"unlockedCardsWrapperCollapsed": "unlocked-cards_unlocked-cards-wrapper-collapsed_37-6d",
	"unlocked-cards-heading": "unlocked-cards_unlocked-cards-heading_1f_ym",
	"unlockedCardsHeading": "unlocked-cards_unlocked-cards-heading_1f_ym",
	"unlocked-cards-items-container": "unlocked-cards_unlocked-cards-items-container_3RX6q",
	"unlockedCardsItemsContainer": "unlocked-cards_unlocked-cards-items-container_3RX6q"
};
module.exports = exports;
