import { ADD_SCHOOLS } from '../actions/schools'

const initialState = {
  list: [],
  currentSchool: null,
}

export default function schoolsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SCHOOLS:
      return {
        ...state,
        list: [
          ...action.payload,
        ],
      }
    default:
      return state
  }
}