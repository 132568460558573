const ADD_SCHOOLS = 'ADD_SCHOOLS';

export function addSchools(schools) {
  return {
    type: ADD_SCHOOLS,
    payload: schools,
  }
}

export {
  ADD_SCHOOLS,
};