import React from 'react';
import classNames from 'classnames';

import LibraryItem from '../../containers/library-item.jsx';

import styles from './unlocked-card.css';

const UnlockedCard = ({ objectName, data, onClick, index, isFlipped }) => {
    const objectData = data.find(s => s.name === objectName);

    const handleClick = () => {
        onClick(objectName);
    }

    return (
        <div className={styles.unlockedCardContainer} onClick={handleClick}>
            <div className={classNames(styles.unlockedCardInner, {
                    [styles.unlockedCardInnerFlipped]: isFlipped,
                })}>
                <div className={styles.unlockedCardFront}>
                    <div className={styles.unlockedCardFrontInner}>
                        ?
                    </div>
                </div>
                <div className={styles.unlockedCardBack}>
                    <LibraryItem
                        {...objectData}
                        iconMd5={objectData.costumes ? objectData.costumes[0].md5ext : objectData.md5ext}
                        iconRawURL={objectData.costumes ? objectData.costumes[0].rawURL : objectData.rawURL}
                        icons={objectData.costumes}
                        id={index}
                        onMouseEnter={() => {}}
                        onMouseLeave={() => {}}
                        onSelect={() => {}}
                    />
                </div>
            </div>
        </div>
    );
};

export default UnlockedCard;