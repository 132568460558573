const CLEAR_CURRENT_STUDENT = 'CLEAR_CURRENT_STUDENT';
const SET_CURRENT_STUDENT = 'SET_CURRENT_STUDENT';
const STUDENT_UNLOCK_SPRITE = 'STUDENT_UNLOCK_SPRITE';
const STUDENT_LOAD_UNLOCKED_SPRITES = 'STUDENT_LOAD_UNLOCKED_SPRITES';
const STUDENT_UNLOCK_BACKDROP = 'STUDENT_UNLOCK_BACKDROP';
const STUDENT_LOAD_UNLOCKED_BACKDROPS = 'STUDENT_LOAD_UNLOCKED_BACKDROPS';

export function clearCurrentStudent() {
  return {
    type: CLEAR_CURRENT_STUDENT,
  }
}

export function setCurrentStudent(student) {
  return {
    type: SET_CURRENT_STUDENT,
    payload: student,
  }
}

export function unlockSprite(spriteId) {
  return {
    type: STUDENT_UNLOCK_SPRITE,
    payload: { spriteId },
  }
}

export function unlockBackdrop(backdropId) {
  return {
    type: STUDENT_UNLOCK_BACKDROP,
    payload: { backdropId },
  }
}

export function loadUnlockedSprites(unlockedSprites) {
  return {
    type: STUDENT_LOAD_UNLOCKED_SPRITES,
    payload: {
      sprites: unlockedSprites,
    },
  }
}

export function loadUnlockedBackdrops(unlockedBackdrops) {
  return {
    type: STUDENT_LOAD_UNLOCKED_BACKDROPS,
    payload: {
      backdrops: unlockedBackdrops,
    },
  }
}

export {
    CLEAR_CURRENT_STUDENT,
    SET_CURRENT_STUDENT,
    STUDENT_UNLOCK_SPRITE,
    STUDENT_UNLOCK_BACKDROP,
    STUDENT_LOAD_UNLOCKED_SPRITES,
    STUDENT_LOAD_UNLOCKED_BACKDROPS,
};