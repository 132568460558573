import endpoints from '../config/api';

async function saveFile(fileName, content) {
  try {
    const url = `${endpoints.save_file}/${fileName}`; 
    const response = await fetch(url, {
      mode: 'cors',
      method: 'PUT',
      body: content,
    });
    if (!response.ok) {
      console.error(`Failed to save file: ${response.status}`);
      return;
    }
    return response.json();
  } catch (error) {
    console.error('Failed to save file: ', error);
  }
}

async function fetchFile(fileName) {
  try {
    const url = `${endpoints.get_file}/${fileName}`;
    const response = await fetch(url, {
      mode: 'cors',
    });
    if (!response.ok) {
      console.error(`Failed to fetch file: ${response.status}`);
      return;
    }
    return response;
  } catch (error) {
    console.error('Failed to fetch file: ', error);
  }

  return null;
}

async function fetchAllFiles(studentId) {
  try {
    const url = `${endpoints.get_file}/${studentId}`;
    const response = await fetch(url, {
      mode: 'cors',
      type: 'application/json',
    });
    if (!response.ok) {
      console.error(`Failed to fetch files: ${response.status}`);
      return;
    }
    return response.json();
  } catch (error) {
    console.error('Failed to fetch file: ', error);
  }

  return null;
}

export {
  saveFile,
  fetchFile,
  fetchAllFiles,
};