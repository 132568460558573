import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import LibraryItem from '../../containers/library-item.jsx';
import Modal from '../../containers/modal.jsx';
import Divider from '../divider/divider.jsx';
import Filter from '../filter/filter.jsx';
import TagButton from '../../containers/tag-button.jsx';
import Spinner from '../spinner/spinner.jsx';

import { useSelector, useDispatch } from '../../onboarding/store';
import { 
    getCurrentStudent, 
    getUnlockedSprites, 
    getUnlockedSpritesOfTheDay, 
    getUnlockedBackDrops, 
    getUnlockedBackDropsOfTheDay
} from '../../onboarding/store/selectors/students.jsx';
import { unlockBackdrop, unlockSprite } from '../../onboarding/store/actions/students.jsx';

import styles from './library.css';
import UnlockedCards from './unlocked-cards.jsx';
import LibraryTypes from './types.jsx';

import sprites from '../../lib/libraries/sprites.json';
import backdrops from '../../lib/libraries/backdrops.json';

const messages = defineMessages({
    filterPlaceholder: {
        id: 'gui.library.filterPlaceholder',
        defaultMessage: 'Search',
        description: 'Placeholder text for library search field'
    },
    allTag: {
        id: 'gui.library.allTag',
        defaultMessage: 'All',
        description: 'Label for library tag to revert to all items after filtering by tag.'
    }
});

const ALL_TAG = {tag: 'all', intlLabel: messages.allTag};
const tagListPrefix = [ALL_TAG];

class LibraryComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleFilterChange',
            'handleFilterClear',
            'handleMouseEnter',
            'handleMouseLeave',
            'handlePlayingEnd',
            'handleSelect',
            'handleTagClick',
            'setFilteredDataRef'
        ]);
        this.state = {
            playingItem: null,
            filterQuery: '',
            selectedTag: ALL_TAG.tag,
            loaded: false
        };
    }
    componentDidMount () {
        // Allow the spinner to display before loading the content
        setTimeout(() => {
            this.setState({loaded: true});
        });
        if (this.props.setStopHandler) this.props.setStopHandler(this.handlePlayingEnd);
    }
    componentDidUpdate (prevProps, prevState) {
        if (prevState.filterQuery !== this.state.filterQuery ||
            prevState.selectedTag !== this.state.selectedTag) {
            this.scrollToTop();
        }
    }
    handleSelect (id) {
        this.handleClose();
        this.props.onItemSelected(this.getFilteredData()[id]);
    }
    handleClose () {
        this.props.onRequestClose();
    }
    handleTagClick (tag) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: '',
                selectedTag: tag.toLowerCase()
            });
        } else {
            this.props.onItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
            this.setState({
                filterQuery: '',
                playingItem: null,
                selectedTag: tag.toLowerCase()
            });
        }
    }
    handleMouseEnter (id) {
        // don't restart if mouse over already playing item
        if (this.props.onItemMouseEnter && this.state.playingItem !== id) {
            this.props.onItemMouseEnter(this.getFilteredData()[id]);
            this.setState({
                playingItem: id
            });
        }
    }
    handleMouseLeave (id) {
        if (this.props.onItemMouseLeave) {
            this.props.onItemMouseLeave(this.getFilteredData()[id]);
            this.setState({
                playingItem: null
            });
        }
    }
    handlePlayingEnd () {
        if (this.state.playingItem !== null) {
            this.setState({
                playingItem: null
            });
        }
    }
    handleFilterChange (event) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: event.target.value,
                selectedTag: ALL_TAG.tag
            });
        } else {
            this.props.onItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
            this.setState({
                filterQuery: event.target.value,
                playingItem: null,
                selectedTag: ALL_TAG.tag
            });
        }
    }
    handleFilterClear () {
        this.setState({filterQuery: ''});
    }
    getFilteredData () {
        // First filter the locked items
        var unlockedItems = this.props.data.filter(dataItem => !dataItem.unlocking || 
            (this.props.type === LibraryTypes.SPRITE && this.props.allUnlockedSprites[dataItem.name]) ||
            (this.props.type === LibraryTypes.BACKDROP && this.props.allUnlockedBackDrops[dataItem.name])
        ).map(dataItem => {
            const unlockedItem = this.props.allUnlockedSprites[dataItem.name] || this.props.allUnlockedBackDrops[dataItem.name];
            return {
                ...dataItem,
                unlockedOn: unlockedItem ? unlockedItem.unlockedOn : null
            }
        });

        // Deduplicate the unlocked items by name
        unlockedItems = unlockedItems.filter((dataItem, index, self) =>
            index === self.findIndex(t => (
                t.name === dataItem.name
            ))
        );

        // Then sort the unlocked items (most recent first)
        unlockedItems.sort((a, b) => {
            if (a.unlockedOn > b.unlockedOn) {
                return -1;
            }
            if (a.unlockedOn < b.unlockedOn) {
                return 1;
            }
            return 0;
        });

        if (this.state.selectedTag === 'all') {            
            if (!this.state.filterQuery) return unlockedItems;
            return unlockedItems.filter(dataItem => (
                (dataItem.tags || [])
                    // Second argument to map sets `this`
                    .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                    .concat(dataItem.name ?
                        (typeof dataItem.name === 'string' ?
                        // Use the name if it is a string, else use formatMessage to get the translated name
                            dataItem.name : this.props.intl.formatMessage(dataItem.name.props)
                        ).toLowerCase() :
                        null)
                    .join('\n') // unlikely to partially match newlines
                    .indexOf(this.state.filterQuery.toLowerCase()) !== -1
            ));
        }
        return unlockedItems.filter(dataItem => (
            dataItem.tags &&
            dataItem.tags
                .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                .indexOf(this.state.selectedTag) !== -1
        ));
    }
    scrollToTop () {
        this.filteredDataRef.scrollTop = 0;
    }
    setFilteredDataRef (ref) {
        this.filteredDataRef = ref;
    }
    render () {
        return (
            <Modal
                fullScreen
                contentLabel={this.props.title}
                id={this.props.id}
                onRequestClose={this.handleClose}
            >
                {(this.props.filterable || this.props.tags) && (
                    <div className={styles.filterBar}>
                        {this.props.filterable && (
                            <Filter
                                className={classNames(
                                    styles.filterBarItem,
                                    styles.filter
                                )}
                                filterQuery={this.state.filterQuery}
                                inputClassName={styles.filterInput}
                                placeholderText={this.props.intl.formatMessage(messages.filterPlaceholder)}
                                onChange={this.handleFilterChange}
                                onClear={this.handleFilterClear}
                            />
                        )}
                        {this.props.filterable && this.props.tags && (
                            <Divider className={classNames(styles.filterBarItem, styles.divider)} />
                        )}
                        {this.props.tags &&
                            <div className={styles.tagWrapper}>
                                {tagListPrefix.concat(this.props.tags).map((tagProps, id) => (
                                    <TagButton
                                        active={this.state.selectedTag === tagProps.tag.toLowerCase()}
                                        className={classNames(
                                            styles.filterBarItem,
                                            styles.tagButton,
                                            tagProps.className
                                        )}
                                        key={`tag-button-${id}`}
                                        onClick={this.handleTagClick}
                                        {...tagProps}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                )}

                {this.props.type === LibraryTypes.SPRITE && this.props.unlockedSprites.length > 0 && 
                    <UnlockedCards 
                        objects={this.props.unlockedSprites} 
                        data={this.props.data} 
                        type={this.props.type}
                    />
                }

                {this.props.type === LibraryTypes.BACKDROP && this.props.unlockedBackDrops.length > 0 && 
                    <UnlockedCards 
                        objects={this.props.unlockedBackDrops} 
                        data={this.props.data} 
                        type={this.props.type}
                    />
                }

                <div
                    className={classNames(styles.libraryScrollGrid, {
                        [styles.withFilterBar]: this.props.filterable || this.props.tags
                    })}
                    ref={this.setFilteredDataRef}
                >
                    {this.state.loaded ? this.getFilteredData().map((dataItem, index) => (
                        <LibraryItem
                            bluetoothRequired={dataItem.bluetoothRequired}
                            collaborator={dataItem.collaborator}
                            description={dataItem.description}
                            disabled={dataItem.disabled}
                            extensionId={dataItem.extensionId}
                            featured={dataItem.featured}
                            hidden={dataItem.hidden}
                            iconMd5={dataItem.costumes ? dataItem.costumes[0].md5ext : dataItem.md5ext}
                            iconRawURL={dataItem.costumes ? dataItem.costumes[0].rawURL : dataItem.rawURL}
                            icons={dataItem.costumes}
                            id={index}
                            insetIconURL={dataItem.insetIconURL}
                            internetConnectionRequired={dataItem.internetConnectionRequired}
                            isPlaying={this.state.playingItem === index}
                            key={typeof dataItem.name === 'string' ? dataItem.name : dataItem.rawURL}
                            name={dataItem.name}
                            showPlayButton={this.props.showPlayButton}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                            onSelect={this.handleSelect}
                        />
                    )) : (
                        <div className={styles.spinnerWrapper}>
                            <Spinner
                                large
                                level="primary"
                            />
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

LibraryComponent.propTypes = {
    data: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            md5: PropTypes.string,
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            rawURL: PropTypes.string
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    filterable: PropTypes.bool,
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onItemMouseEnter: PropTypes.func,
    onItemMouseLeave: PropTypes.func,
    onItemSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    setStopHandler: PropTypes.func,
    showPlayButton: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.shape(TagButton.propTypes)),
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

LibraryComponent.defaultProps = {
    filterable: true,
    showPlayButton: false
};

const mapStateToProps = state => ({
    
});

const ConnectedLibraryComponent = injectIntl(connect(
    mapStateToProps
)(LibraryComponent));

// We need to wrap MenuBarComponent in a function that returns a component
// Because we need to access hooks and MenuBarComponent is a class component
const LibraryComponentWrapped = props => {
    const dispatch = useDispatch();

    const [unlockedSprites, setUnlockedSprites] = useState([]);
    const [unlockedBackDrops, setUnlockedBackDrops] = useState([]);

    const currentStudent = useSelector(getCurrentStudent);

    const unlockedSpritesOfTheDay = useSelector(getUnlockedSpritesOfTheDay);
    const allUnlockedSprites = useSelector(getUnlockedSprites);

    const unlockedBackDropsOfTheDay = useSelector(getUnlockedBackDropsOfTheDay);
    const allUnlockedBackDrops = useSelector(getUnlockedBackDrops);

    useEffect(() => {
        if (props.type === LibraryTypes.SPRITE && unlockedSpritesOfTheDay.length === 0) {
            const lockedSprites = sprites.filter(sprite => sprite.unlocking && !allUnlockedSprites[sprite.name]);
            
            // Take randomly 5 locked sprites from categories: 2 from the "character" category, 2 from the "doodad" category, 1 from the "rare" category
            // These should be unique
            const charactersToUnlock = lockedSprites.filter(sprite => sprite.unlocking.category === 'character').sort(() => 0.5 - Math.random()).slice(0, 2);
            const doodadsToUnlock = lockedSprites.filter(sprite => sprite.unlocking.category === 'doodad').sort(() => 0.5 - Math.random()).slice(0, 2);
            const rareToUnlock = lockedSprites.filter(sprite => sprite.unlocking.category === 'rare').sort(() => 0.5 - Math.random()).slice(0, 1);

            const spritesToUnlock = [...charactersToUnlock, ...doodadsToUnlock, ...rareToUnlock].map(sprite => sprite.name);

            setUnlockedSprites(spritesToUnlock);

            for (const sprite of spritesToUnlock) {
                dispatch(unlockSprite(sprite));
            }
        }
    }, [unlockedSpritesOfTheDay]);

    useEffect(() => {
        if (props.type === LibraryTypes.BACKDROP && unlockedBackDropsOfTheDay.length === 0) {
            const lockedBackdrops = backdrops.filter(backdrop => backdrop.unlocking && !allUnlockedBackDrops[backdrop.name]);

            // Take randomly 3 locked backdrops (or less if there are less than 3) to unlock
            const backdropsToUnlock = lockedBackdrops.sort(() => 0.5 - Math.random()).slice(0, 3).map(backdrop => backdrop.name);

            setUnlockedBackDrops(backdropsToUnlock);

            for (const backdrop of backdropsToUnlock) {
                dispatch(unlockBackdrop(backdrop));
            }
        }
    }, [unlockedBackDropsOfTheDay]);

    return (
        <ConnectedLibraryComponent 
            {...props} 
            currentStudent={currentStudent} 
            unlockedSprites={unlockedSprites}
            unlockedBackDrops={unlockedBackDrops}
            allUnlockedSprites={allUnlockedSprites}
            allUnlockedBackDrops={allUnlockedBackDrops}
        />
    );
};

export default LibraryComponentWrapped;