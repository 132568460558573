const getCurrentStudent = state => state.students.currentStudent;
const getStudents = state => state.students.students || {};
const getCurrentStudentStorage = state => {
  const student = getCurrentStudent(state);
  if (!student || !student.id) {
    return {};
  }
  return getStudents(state)[student.id] || {};
}

const getUnlockedSprites = state => {
  const studentStorage = getCurrentStudentStorage(state);
  return studentStorage.unlocked_sprites || {};
}

const getUnlockedSpritesOfTheDay = state => {
  const unlockedSprites = getUnlockedSprites(state);
  return Object.keys(unlockedSprites).filter(spriteName => {
    const unlockedOn = unlockedSprites[spriteName].unlockedOn;
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return unlockedOn >= today.getTime();
  }).map(spriteName => ({
    ...unlockedSprites[spriteName],
    name: spriteName,
  }));
}

const getUnlockedBackDrops = state => {
  const studentStorage = getCurrentStudentStorage(state);
  return studentStorage.unlocked_backdrops || {};
}

const getUnlockedBackDropsOfTheDay = state => {
  const unlockedBackdrops = getUnlockedBackDrops(state);
  return Object.keys(unlockedBackdrops).filter(backdropName => {
    const unlockedOn = unlockedBackdrops[backdropName].unlockedOn;
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return unlockedOn >= today.getTime();
  }).map(backdropName => ({
    ...unlockedBackdrops[backdropName],
    name: backdropName,
  }));
}

export {
  getCurrentStudent,
  getUnlockedSprites,
  getUnlockedSpritesOfTheDay,
  getUnlockedBackDrops,
  getUnlockedBackDropsOfTheDay,
  getCurrentStudentStorage,
};