import { 
  CLEAR_CURRENT_STUDENT,
  SET_CURRENT_STUDENT, 
  STUDENT_UNLOCK_SPRITE, 
  STUDENT_UNLOCK_BACKDROP,
  STUDENT_LOAD_UNLOCKED_SPRITES,
  STUDENT_LOAD_UNLOCKED_BACKDROPS,
} from '../actions/students'

const initialState = {
  currentStudent: null,
  students: {},
}

const initialStudentState = {
  id: null,
  storageKey: null,
  sessionId: null,
  name: '',
  unlocked_sprites: {},
  unlocked_backdrops: {},
};

function getOrCreateStudent(students, id) {
  const existingStudent = students[id];
  if (existingStudent) {
    return existingStudent;
  }
  return { ...initialStudentState, id };
}

export default function studentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_STUDENT:
      if (!action.payload) {
        return {
          ...state,
          currentStudent: null,
        }
      }

      return {
        ...state,
        currentStudent: {
          ...action.payload,
          sessionStartedOn: (state.currentStudent && state.currentStudent.sessionStartedOn) ? state.currentStudent.sessionStartedOn : Date.now(),
        }
      }
    
    case CLEAR_CURRENT_STUDENT:
      return {
        ...state,
        currentStudent: null,
      }

    case STUDENT_UNLOCK_SPRITE: {
      const { spriteId } = action.payload;
      const studentId = state.currentStudent.id;
      const student = getOrCreateStudent((state.students || {}), studentId);
      const newStudent = {
        ...student,
        unlocked_sprites: {
          ...(student.unlocked_sprites || {}),
          [spriteId]: {
            unlockedOn: Date.now()
          }
        }
      };

      return {
        ...state,
        students: {
          ...state.students,
          [studentId]: newStudent,
        }
      }
    }

    case STUDENT_UNLOCK_BACKDROP: {
      const { backdropId } = action.payload;
      const studentId = state.currentStudent.id;
      const student = getOrCreateStudent((state.students || {}), studentId);
      const newStudent = {
        ...student,
        unlocked_backdrops: {
          ...(student.unlocked_backdrops || {}),
          [backdropId]: {
            unlockedOn: Date.now()
          }
        }
      };

      return {
        ...state,
        students: {
          ...state.students,
          [studentId]: newStudent,
        }
      }
    }

    case STUDENT_LOAD_UNLOCKED_SPRITES: {
      const { sprites } = action.payload;
      const studentId = state.currentStudent.id;
      const student = getOrCreateStudent((state.students || {}), studentId);
      const newStudent = {
        ...student,
        unlocked_sprites: {
          ...(student.unlocked_sprites || {}),
          ...sprites,
        }
      };

      return {
        ...state,
        students: {
          ...state.students,
          [studentId]: newStudent,
        }
      }
    }

    case STUDENT_LOAD_UNLOCKED_BACKDROPS: {
      const { backdrops } = action.payload;
      const studentId = state.currentStudent.id;
      const student = getOrCreateStudent((state.students || {}), studentId);
      const newStudent = {
        ...student,
        unlocked_backdrops: {
          ...(student.unlocked_backdrops || {}),
          ...backdrops,
        }
      };

      return {
        ...state,
        students: {
          ...state.students,
          [studentId]: newStudent,
        }
      }
    }

    default:
      return state
  }
}