
import React, { useEffect, useState } from 'react';

import LibraryTypes from './types.jsx';
import UnlockedCard from './unlocked-card';
import styles from './unlocked-cards.css';
import classNames from 'classnames';

const objectNames = {
    [LibraryTypes.BACKDROP]: 'Backdrops',
    [LibraryTypes.SPRITE]: 'Sprites'   
}

const UnlockedCards = ({ objects, data, type }) => {
    const [flippedItems, setFlippedItems] = useState([]);
    const [allFlipped, setAllFlipped] = useState(false);

    const flipItem = (item) => {
        setFlippedItems([...flippedItems, item]);
    }

    useEffect(() => {
        if (flippedItems.length === objects.length) {
            setTimeout(() => {
                setAllFlipped(true);
            }, 800);
        }
    }, [flippedItems, objects]);

    return (
        <div className={classNames(styles.unlockedCardsWrapper, {
            [styles.unlockedCardsWrapperCollapsed]: allFlipped
        })}>
            <div className={styles.unlockedCardsHeading}>New {objectNames[type]}!</div>
            <div className={styles.unlockedCardsItemsContainer}>
                {objects.map((objectName, index) => 
                    <UnlockedCard
                        key={objectName}
                        index={index}
                        objectName={objectName}
                        data={data}
                        onClick={flipItem} 
                        isFlipped={flippedItems.includes(objectName)}
                    />
                )}
            </div>
        </div>
    );
};

export default UnlockedCards;