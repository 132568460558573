import { createStore } from 'redux';
import { createSelectorHook, createStoreHook, createDispatchHook } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';
import { ProviderContext } from './context';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['students']
};
  
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

const useSelector = createSelectorHook(ProviderContext);
const useStore = createStoreHook(ProviderContext)
const useDispatch = createDispatchHook(ProviderContext)
  
export { 
  store,
  persistor,
  useSelector,
  useStore,
  useDispatch
};