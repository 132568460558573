import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {projectTitleInitialState} from '../reducers/project-title';
import downloadBlob from '../lib/download-blob';

import { saveFile } from '../onboarding/api/files';

/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <AWSDownloader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</AWSDownloader>
 */
class AWSDownloader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'downloadProject'
        ]);
    }
    downloadProject () {
        this.props.saveProjectSb3().then(async (content) => {
            if (this.props.onSaveFinished) {
                this.props.onSaveFinished();
            }
    
            try {
                // List of valid phone numbers
                const validPhoneNumbers = [
                    '9495724723', '9493759813', '9259159979', '9257866032', '9254485580', '9254445177',
                    '9169563285', '9168494135', '8027600820', '7607991410', '7192317916', '7148515646',
                    '7143225720', '6692340128', '6572425217', '6509194134', '6508885485', '6507976925',
                    '6507932921', '6507879898', '6506099180', '6505344448', '6504304973', '6502817903',
                    '6282252468', '6267261411', '6198364773', '5622005784', '5109929916', '5108272485',
                    '5107341278', '5107087636', '5107082101', '5106047410', '5105858847', '5105098522',
                    '5105023030', '5104933477', '5103860391', '5103647848', '5103294628', '5103045714',
                    '4346613939', '4234631579', '4159809339', '4159800617', '4156729900', '4152354995',
                    '4089906724', '4089529797', '4089215513', '4089109486', '4089080785', '4088963107',
                    '4088549429', '4088542505', '4088338574', '4088335354', '4088323233', '4088206739',
                    '4088029095', '4087914039', '4086858577', '4086424422', '4086393115', '4086184106',
                    '4086184106', '4086137323', '4086134632', '4085153336', '4085043578', '4084785676',
                    '4084609661', '4084427432', '4084299049', '4084290520', '4083879248', '4083244207',
                    '4073425141', '4014405136', '3477224145', '3416996313', '3215143322', '2482253245',
                    '2292898045', '2292898045', '2099811702', '2092250042', '2038086261', '4088206739',
                    '4088236739', '5103141343', '6503079609'
                ];
    
                const passcode = window.prompt('Enter phone number to save:');
    
                // Check if the passcode matches any valid phone number
                if (validPhoneNumbers.includes(passcode)) {
                    const fileName = `${this.props.currentStudent.storageKey}/${this.props.currentStudent.sessionId}__${this.props.challengeId}.sb3`;
                    await saveFile(fileName, content);
                    window.alert('Project saved successfully');
                } else if (passcode !== null) {
                    window.alert('Incorrect phone number. Save aborted.');
                }
            } catch (error) {
                console.error('Failed to save project: ', error);
            }
        });
    }
    render () {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.downloadProject
        );
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.sb3`;
};

AWSDownloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func,
    currentStudent: PropTypes.object,
    challengeId: PropTypes.string,
};
AWSDownloader.defaultProps = {
    className: ''
};

const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState)
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(AWSDownloader);
